// 进口路由
import { createRouter, createWebHistory } from "vue-router"

// 进口仪表板视图模板
import systemLogin from '../pages/public/login'
import systemHome from '../pages/public/home'
import dashboard from '../pages/master/dashboard'
import tradeOrder from '../pages/trade/order' // 交易管理-订单列表
import tradeRecharge from '../pages/trade/recharge' // 交易管理-充值管理
import tradeWithdraw from '../pages/trade/withdraw' // 交易管理-提现管理
import tradeControl from '../pages/trade/control' // 交易管理-交易控制
import tradeAmount from '../pages/trade/amount' // 交易管理-金额调整
import tradeStatistics from '../pages/trade/statistics' // 交易管理-统计报表
import tradeReport from '../pages/trade/report' // 交易管理-代理报表
import tradeReportDaily from '../pages/trade/daily' // 交易管理-代理日报表
import tradeReportMonthly from '../pages/trade/monthly' // 交易管理-代理月报表
import commodityList from '../pages/commodity/list/index' // 商品管理-商品列表
import commoditySort from '../pages/commodity/sort/index' // 商品管理-商品分类
import commodityAdd from '../pages/commodity/add' // 商品管理-添加商品
import commodityEdit from '../pages/commodity/edit' // 商品管理-编辑商品

import memberList from '../pages/member/list/index' // 会员管理-会员列表
import memberSort from '../pages/member/grade/index' // 会员管理-会员等级
import memberLevel from '../pages/member/level/index' // 会员管理-会中层级
import memberAmount from '../pages/member/amount' // 会员管理-会员帐变

import memberTeam from '../pages/member/team' // 团队
import contentNotice from '../pages/content/notice/index' // 内容管理-公告管理
import contentText from '../pages/content/text/index' // 内容管理-文本管理
import contentAd from '../pages/content/ad/index' // 内容管理-首页轮播图
import contentLogo from '../pages/content/logo/index' // 内容管理-LOGO
import powerList from '../pages/power/list/index' // 权限管理-访问权限
import powerAdmin from '../pages/power/admin/index' // 权限管理-系统用户
import systemAccount from '../pages/system/account' // 系统管理-系统帐号管理
import systemMenu from '../pages/system/menu/index' // 系统管理-系统菜单管理
import systemParameter from '../pages/system/parameter/index' // 系统管理-系统参数配置
import systemLog from '../pages/system/log' // 系统管理-系统操作日志

import powerTeam from '../pages/acl/team' // 系统管理-权限管理-权组管理
import power from '../pages/acl/power' // 系统管理-权限管理-权限管理
import selectIcon from '../components/SelectIcon' // 系统管理-权限管理-选择图标
import position from '../pages/acl/position'
import powerSetup from '../pages/acl/setup'
import orderDesignate from '../pages/trade/designate'



const routes = [
    {
        name: 'systemHome',
        // 路由的 URL 字串
        path: '/',
        component: systemHome,
        meta: {
            title: '首页'
        }
    },
    {
        name: 'systemHome',
        // 路由的 URL 字串
        path: '',
        component: systemHome,
        meta: {
            title: '首页'
        }
    },
    {
        name: 'system',
        // 路由的 URL 字串
        path: '/system',
        component: dashboard,
        meta: {
            title: '首页'
        }
    },
    {
        name: 'tradeOrder',
        path: '/trade/order',
        component: tradeOrder,
        meta: {
            title: '订单列表-交易管理'
        }
    },
    {
        name: 'tradeRecharge',
        path: '/trade/recharge',
        component: tradeRecharge,
        meta: {
            title: '充值管理-交易管理'
        }
    },
    {
        name: 'tradeWithdraw',
        path: '/trade/withdraw',
        component: tradeWithdraw,
        meta: {
            title: '提现管理-交易管理'
        }
    },
    {
        name: 'tradeControl',
        path: '/trade/control',
        component: tradeControl,
        meta: {
            title: '交易控制-交易管理'
        }
    },
    {
        name: 'tradeAmount',
        path: '/trade/amount',
        component: tradeAmount,
        meta: {
            title: '金额调整-交易管理'
        }
    },
    {
        name: 'tradeStatistics',
        path: '/trade/statistics',
        component: tradeStatistics,
        meta: {
            title: '统计报表-交易管理'
        }
    },
    {
        name: 'tradeReport',
        path: '/trade/report',
        component: tradeReport,
        meta: {
            title: '代理报表-交易管理'
        }
    },
    {
        name: 'tradeReportDaily',
        path: '/trade/report/daily',
        component: tradeReportDaily,
        meta: {
            title: '代理日报表-交易管理'
        }
    },
    {
        name: 'tradeReportMonthly',
        path: '/trade/report/monthly',
        component: tradeReportMonthly,
        meta: {
            title: '代理月报表-交易管理'
        }
    },
    {
        name: 'commodityList',
        path: '/commodity/list',
        component: commodityList,
        meta: {
            title: '商品列表-商品管理'
        }
    },
    {
        name: 'commoditySort',
        path: '/commodity/sort',
        component: commoditySort,
        meta: {
            title: '商品分类-商品管理'
        }
    },
    {
        name: 'commodityAdd',
        path: '/commodity/add',
        component: commodityAdd,
        meta: {
            title: '商品列表-添加商品'
        }
    },
    {
        name: 'commodityEdit',
        path: '/commodity/edit',
        component: commodityEdit,
        meta: {
            title: '商品列表-添加商品'
        }
    },

    {
        // 会员列表
        name: 'memberList',
        path: '/member/list',
        component: memberList,
        meta: {
            title: '会员列表-会员管理'
        }
    },
    {
        // 会员等级
        name: 'memberSort',
        path: '/member/grade',
        component: memberSort,
        meta: {
            title: '会员等级-会员管理'
        }
    },
    {
        // 会中层级
        name: 'memberLevel',
        path: '/member/level',
        component: memberLevel,
        meta: {
            title: '会员层级-会员管理'
        }
    },
    {
        // 团队
        name: 'memberTeam',
        path: '/member/team',
        component: memberTeam,
        meta: {
            title: '团队-会员管理'
        }
    },
    {
        // 会员管理-会员帐变
        name: 'memberAmount',
        path: '/member/amount',
        component: memberAmount,
        meta: {
            title: '会员帐变-会员管理'
        }
    },

    {
        // 内容管理-公告管理
        name: 'contentNotice',
        path: '/content/notice',
        component: contentNotice,
        meta: {
            title: '公告管理-内容管理'
        }
    },
    {
        // 内容管理-文本管理
        name: 'contentText',
        path: '/content/text',
        component: contentText,
        meta: {
            title: '文本管理-内容管理'
        }
    },
    {
        // 内容管理-首页轮播图
        name: 'contentAd',
        path: '/content/ad',
        component: contentAd,
        meta: {
            title: '首页轮播图-内容管理'
        }
    },
    {
        // 内容管理-LOGO
        name: 'contentLogo',
        path: '/content/logo',
        component: contentLogo,
        meta: {
            title: 'LOGO-内容管理'
        }
    },
    {
        // 权限管理-访问权限
        name: 'powerList',
        path: '/power/list',
        component: powerList,
        meta: {
            title: '访问权限-权限管理'
        }
    },
    {
        // 权限管理-系统用户
        name: 'powerAdmin',
        path: '/power/admin',
        component: powerAdmin,
        meta: {
            title: '系统用户-权限管理'
        }
    },
    {
        // 系统管理-系统帐号管理
        name: 'systemAccount',
        path: '/system/account',
        component: systemAccount,
        meta: {
            title: '系统帐号管理-系统管理'
        }
    },
    
    {
        // 系统管理-系统参数配置
        name: 'systemParameter',
        path: '/system/parameter',
        component: systemParameter,
        meta: {
            title: '系统参数配置-系统管理'
        }
    },
    {
        // 系统管理-系统菜单管理
        name: 'systemMenu',
        path: '/system/menu',
        component: systemMenu,
        meta: {
            title: '系统菜单管理-系统管理'
        }
    },
    {
        // 系统管理-操作日志
        name: 'systemLog',
        path: '/system/log',
        component: systemLog,
        meta: {
            title: '操作日志-系统管理'
        }
    },
    {
        // 登录系统
        name: 'systemLogin',
        path: '/system/login',
        component: systemLogin,
        meta: {
            title: '系统管理'
        }
    },
    {
        // 权组管理
        name: 'powerTeam',
        path: '/system/acl/team',
        component: powerTeam,
        meta: {
            title: 'powerTeam'
        }
    },
    {
        name: 'selectIcon',
        path: '/system/select/icon',
        component: selectIcon,
        meta: {
            title: 'selectIcon'
        }
    },
    {
        // 权限管理
        name: 'power',
        path: '/system/acl/power',
        component: power,
        meta: {
            title: '权限管理'
        }
    },
    {
        // 职务管理
        name: 'position',
        path: '/system/acl/position',
        component: position,
        meta: {
            title: '职务管理'
        }
    },
    {
        // 给职务配置权限
        name: 'powerSetup',
        path: '/system/acl/setup/position',
        component: powerSetup,
        meta: {
            title: '职务权限配置'
        }
    },
    {
        // 指派任务
        name: 'orderDesignate',
        path: '/system/order/designate',
        component: orderDesignate,
        meta: {
            title: '指派任务'
        }
    },



]


const router = new createRouter({

    history: createWebHistory(),
    routes
})

// 在路由跳转前执行的操作
router.beforeEach((to, from, next) => {
    // 在这里进行权限验证、登录判断等操作
    // console.log('在这里进行权限验证、登录判断等操作')
    // console.log('跳转到:', to.path)
    // console.log('来自:', from.path)
    // console.log('token:', localStorage.getItem('token') )

    if (to.path !== '/' && !localStorage.getItem('token')) {
        next('/')
    } else {
        next() // 允许路由跳转 跳转到下一个路由
    }
})

// 在路由跳转后执行的操作
router.afterEach(() => {
    // 跳转后的操作
    // console.log('路由跳转完成')
})

// 在导航被确认之前，所有组件内守卫和异步路由组件被解析之后调用。
router.beforeResolve((to, from, next) => {
    // 在导航被确认之前的操作
    // console.log('在导航被确认之前的操作')
    next()  // 允许路由跳转 跳转到下一个路由
})

export default router

// const router = Router()
// export default router
// function Router() {
//     const router = new createRouter({
//         history: createWebHistory(),
//         routes
//     })
//     return router
// }